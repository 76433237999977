import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { SelectField } from '@politechdev/blocks-design-system'
import { useCachedRequest } from 'hooks/useRequest'
import { fetchJSON, stringifyParams } from 'utils/req'
import { buildOption } from './utils'

export const fetchPublicEventPeople = (eventId, params) =>
  fetchJSON(
    `/api/v1/public_events/${eventId}/people?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: false,
    }
  )

const PublicPersonSelect = ({
  label,
  filters,
  onSelect,
  person,
  fields,
  eventId,
  excludedPersonIds = [],
  ...props
}) => {
  const { t } = useTranslation()
  const { makeRequest, isLoading, errors, hasErrors, response } =
    useCachedRequest((eventId, params) =>
      fetchPublicEventPeople(eventId, params)
    )

  const errorMsg = Object.entries(errors)
    .map(([field, error]) => `${field}: ${error}`)
    .join('; ')
  const [value, setValue] = useState(person?.id)
  const [query, setQuery] = useState()
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    if (value !== person?.id) {
      const selection = response?.people.find(({ id }) => id === value)
      onSelect && onSelect(selection)
    }
  }, [value])

  useEffect(() => {
    if (value !== person?.id) {
      setValue(person?.id)
    }
  }, [person])

  const getPeople = () => {
    makeRequest(`getPersonOptions ${debounced}`, eventId, {
      name: debounced,
    })
  }

  useEffect(() => {
    getPeople()
  }, [debounced])

  const options = [
    ...(person && query === '' ? [person] : []),
    ...(response?.people || []),
  ]
    .reduce(
      (options, option) =>
        options.find(({ id }) => id === option.id)
          ? options
          : [...options, option],
      []
    )
    .filter(option => !excludedPersonIds.includes(option.id))
    .map(buildOption)

  return (
    <SelectField
      label={label || t('Person')}
      options={options}
      onSelect={setValue}
      onInputChange={setQuery}
      value={value}
      loading={isLoading}
      error={hasErrors}
      errorMessage={errorMsg}
      {...props}
    />
  )
}

PublicPersonSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  person: PropTypes.object,
}

PublicPersonSelect.defaultProps = {
  id: 'person',
  filters: [],
  person: null,
}

export default PublicPersonSelect
