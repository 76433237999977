import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LinkToClipboard } from 'components'
import {
  Section,
  Grid,
  DetailItem,
  Font,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { dateTimeFormat } from 'utils/constants'

const EventInfo = ({ event }) => {
  const { t } = useTranslation()

  const start_time_date = moment(event.start_time).format(dateTimeFormat)
  const end_time_date = moment(event.end_time).format(dateTimeFormat)

  return (
    <>
      <PageHeader title={event.public_settings.title || event.name} />
      <Grid>
        <Section>
          {event.location ? (
            <DetailItem label={event.location.name}>
              {event.location.street_address}
              <br />
              {event.location.city}, {event.location.state}{' '}
              {event.location.zipcode}
            </DetailItem>
          ) : null}
          {event.url ? (
            <DetailItem label={t('URL')}>
              <LinkToClipboard
                label={event.url_label}
                url={event.url}
                showCopiedMessage
              />
            </DetailItem>
          ) : null}
          {event.description && (
            <DetailItem label={t('About this event')}>
              <Font.Copy variant="reading-block">{event.description}</Font.Copy>
            </DetailItem>
          )}
        </Section>
        <Section>
          <DetailItem label={t('Event Date')}>
            {start_time_date} to <br />
            {end_time_date}
          </DetailItem>
          <DetailItem label={t('Type')}>{event.type}</DetailItem>
        </Section>
      </Grid>
    </>
  )
}

export default EventInfo
