import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { useCachedRequest } from 'hooks/useRequest'
import { SelectField } from '@politechdev/blocks-design-system'
import { sortBy } from 'lodash'
import { buildMultiOptions } from 'utils/select'
import { buildOrganization, buildOption, fetchPublicEventOrgs } from './utils'

const PublicOrganizationSelectFiel = ({
  label,
  onSelect,
  eventId,
  organizations: selectedOrganizations = [],
}) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState()
  const [debounced] = useDebounce(query, 300)
  const [options, setOptions] = useState([])
  const [multiValue, setMultiValue] = useState(
    sortBy(
      options.filter(option =>
        selectedOrganizations?.some(val => val.id === option.value)
      ),
      ['labels']
    )
  )

  const { makeRequest, isLoading, hasErrors } = useCachedRequest(
    (eventId, params) => fetchPublicEventOrgs(eventId, params),
    {
      onSuccess: ({ organizations: incomingOrganizations }) => {
        setOptions(
          incomingOrganizations
            .reduce(
              (options, option) =>
                options.find(({ id }) => id === option.id)
                  ? options
                  : [...options, option],
              []
            )
            .map(buildOption)
        )
      },
    }
  )

  const getOrganizations = () => {
    makeRequest(`getOrganizationOptions ${debounced}`, eventId, {
      name: debounced,
    })
  }

  useEffect(() => {
    getOrganizations()
  }, [debounced])

  useEffect(() => {
    setMultiValue(sortBy(selectedOrganizations.map(buildOption), ['label']))
  }, [JSON.stringify(selectedOrganizations)])

  const multiOptions = buildMultiOptions(options, multiValue, buildOption)

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildOrganization)
      )
  }

  return (
    <SelectField
      label={label || t('Organizations')}
      options={multiOptions}
      onSelect={handleMultiChange}
      onInputChange={setQuery}
      value={multiValue}
      loading={isLoading}
      error={hasErrors}
      errorMessage={t('Failed to fetch organizations')}
    />
  )
}

export default PublicOrganizationSelectFiel
