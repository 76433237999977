import { useEffect } from 'react'
import { US_STATES } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { PublicOrganizationSelectField } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  Checkbox,
  useToast,
  ProgressBar,
  TextField,
  SelectField,
  Section,
} from '@politechdev/blocks-design-system'
import { useRequest, useLocalForm, useRoutePathParams } from 'hooks'
import { maskZipcode } from 'utils/inputMasks'
import { isValidEmail, sanitizeStr } from 'utils/inputValidations'
import { publicEventRegistrationRequest } from 'requests/events'
import PublicPersonSelect from 'components/PublicPersonSelect/PublicPersonSelect'
import { buildRequest } from './utils'
import styles from './PublicPage.module.scss'

const RegistrationForm = ({ event }) => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { formData, setField } = useLocalForm()
  const settings = event.public_settings || []
  const fields = settings.extra_fields
  const openFieldLabel = settings.other_field_label
  const [{ id: eventId }] = useRoutePathParams()
  const { makeRequest, isLoading, errors, hasErrors, isRequestComplete } =
    useRequest(publicEventRegistrationRequest)

  useEffect(() => {
    if (errors?.person_id?.includes('has already been taken')) {
      setToast({
        message: t('You are already in the guest list for this event'),
        variant: 'error',
      })
    }
  }, [errors])

  const isFormValid = () =>
    !isEmpty(formData.first_name) &&
    !isEmpty(formData.last_name) &&
    !isEmpty(formData.primary_phone_number) &&
    !isEmpty(formData.residential_address?.zipcode)

  const submitForm = e => {
    e.preventDefault()
    makeRequest(buildRequest({ ...formData, id: eventId }))
  }

  if (isRequestComplete && !hasErrors) {
    return (
      <Section label={t('Registration complete')}>
        {event.public_settings.post_registration_message ? (
          <TextBlock className={styles['post-registration-message']}>
            {event.public_settings.post_registration_message}
          </TextBlock>
        ) : (
          <TextBlock>
            {t('Thank you for registering. See you at the event.')}
          </TextBlock>
        )}
      </Section>
    )
  }

  return (
    <>
      <ProgressBar show={isLoading} />
      <Section label="Join us / Participe">
        <form onSubmit={submitForm}>
          <FieldBlock>
            <TextField
              name="first_name"
              id="first_name"
              type="text"
              label="First name / Nombre"
              required
              value={formData.first_name || ''}
              onChange={val => {
                setField(val, 'first_name')
              }}
            />
            <TextField
              name="last_name"
              id="last_name"
              type="text"
              label="Last name / Apellido"
              required
              value={formData.last_name || ''}
              onChange={val => {
                setField(val, 'last_name')
              }}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              type="tel"
              name="primary_phone_number"
              id="primary_phone_number"
              label="Phone number / Teléfono"
              required
              value={formData.primary_phone_number || ''}
              onChange={val => {
                setField(val, 'primary_phone_number')
              }}
            />
            {fields.includes('email') && (
              <TextField
                name="email"
                id="email"
                type="email"
                label={t('Email')}
                value={formData.email || ''}
                onChange={val => {
                  setField(sanitizeStr(val), 'email')
                }}
                error={!!formData.email && !isValidEmail(formData.email)}
                errorMessage={t('Must be a valid email')}
              />
            )}
          </FieldBlock>
          <FieldBlock>
            {fields.includes('street') && (
              <TextField
                name="street_address"
                id="street_address"
                type="text"
                label="Street address / Calle"
                value={formData.residential_address?.line_one || ''}
                onChange={val => {
                  setField(val, '[residential_address][line_one]')
                }}
              />
            )}
            {fields.includes('city') && (
              <TextField
                name="city"
                id="city"
                type="text"
                label="City / Ciudad"
                value={formData.residential_address?.city || ''}
                onChange={val => {
                  setField(val, '[residential_address][city]')
                }}
              />
            )}
          </FieldBlock>
          <FieldBlock>
            {fields.includes('state') && (
              <SelectField
                name="state"
                id="state"
                label="State / Estado"
                options={Object.keys(US_STATES).map(key => ({
                  label: US_STATES[key],
                  value: key,
                }))}
                value={formData.residential_address?.state}
                onSelect={val => {
                  setField(val, '[residential_address][state]')
                }}
              />
            )}
            <TextField
              name="zipcode"
              id="zipcode"
              type="text"
              label="Zip code / Código postal"
              required
              value={formData.residential_address?.zipcode || ''}
              onChange={val => {
                setField(maskZipcode(val), '[residential_address][zipcode]')
              }}
              regex="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
              errorMessage={t('A valid zip code is required')}
            />
          </FieldBlock>
          <FieldBlock>
            {fields.includes('invited_by') && (
              <PublicPersonSelect
                eventId={eventId}
                label="Invited by / Invitado de"
                person={formData.inviter}
                onSelect={val => setField(val, 'inviter')}
              />
            )}
            {fields.includes('organizations') && (
              <PublicOrganizationSelectField
                eventId={eventId}
                label="Organization / Organización"
                organizations={formData.organizations}
                onSelect={val => setField(val, 'organizations')}
              />
            )}
          </FieldBlock>
          {fields.includes('child_care_needs') && (
            <FieldBlock variant="large">
              <TextField
                name="child_care"
                id="child_care"
                type="text"
                label="Child care needs (if any) / Necesidad de cuidado de niños (si hay alguna)"
                value={formData.child_care || ''}
                onChange={val => {
                  setField(val, 'child_care')
                }}
              />
            </FieldBlock>
          )}
          {fields.includes('transportation_needs') && (
            <FieldBlock variant="large">
              <TextField
                name="transportation"
                id="transportation"
                type="text"
                label="Transportation needs (if any) / Necesidad de transporte (si hay alguna)"
                value={formData.transportation || ''}
                onChange={val => {
                  setField(val, 'transportation')
                }}
              />
            </FieldBlock>
          )}
          {fields.includes('dietary_restrictions') && (
            <FieldBlock variant="large">
              <TextField
                name="diet"
                id="diet"
                type="text"
                label="Dietary restrictions (if any) / Restricciones dietéticas (si hay alguna)"
                value={formData.diet || ''}
                onChange={val => {
                  setField(val, 'diet')
                }}
              />
            </FieldBlock>
          )}
          {fields.includes('preferred_language') && (
            <FieldBlock variant="large">
              <TextField
                name="language"
                id="language"
                type="text"
                label="Preferred language / Idioma preferido"
                value={formData.language || ''}
                onChange={val => {
                  setField(val, 'language')
                }}
              />
            </FieldBlock>
          )}
          {fields.includes('other') && (
            <FieldBlock variant="large">
              <TextField
                name="other"
                id="other"
                type="text"
                label={openFieldLabel || 'Other'}
                value={formData.other}
                onChange={val => {
                  setField(val, 'other')
                }}
              />
            </FieldBlock>
          )}
          {fields.includes('receives_sms') && (
            <FieldBlock variant="large">
              <Checkbox
                label="Yes, I would like to receive text messages! / Me gustaría recibir mensajes SMS"
                onChange={val => setField(val, 'receives_sms')}
                checked={formData.receives_sms}
              />
            </FieldBlock>
          )}
          <ButtonBlock justify="left">
            <Button.Accent disabled={!isFormValid() || isLoading} type="submit">
              Register / Registrar
            </Button.Accent>
          </ButtonBlock>
        </form>
      </Section>
    </>
  )
}

export default RegistrationForm
